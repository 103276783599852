<template>
  <div class="event">
    <div class="image">
      <img :src="img" v-if="img" />
    </div>
    <div>
      <div class="place">
        <div class="marker">
          <place-marker />
        </div>
        <span>{{place}}</span>
      </div>
      <div class="title">{{title}}</div>
      <div class="datetime">{{ new Date(datetime*1000) | moment("D MMMM, LT") }}</div>
    </div>
  </div>
</template>

<script>
import PlaceMarker from "@/components/Icons/PlaceMarker";

export default {
  name: "EventCard",
  props: {
    img: {
      type: String,
      default: ""
    },
    place: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    datetime: {
      type: Number,
      default: 0
    }
  },
  components: {
    PlaceMarker
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.event {
  display: flex;

  .image {
    position: relative;
    overflow: hidden;
    min-width: 192px;
    max-width: 192px;
    height: 192px;
    margin-right: 40px;
    border-radius: 16px;
    background: $color_grey_3;
    margin-bottom: 12px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .place {
    display: flex;
    align-items: center;
    width: 100%;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    line-height: 0.89;
    letter-spacing: 0.1px;
    color: $color_grey_a2;

    .marker {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      margin-top: -4px;
      /deep/ svg path {
        fill: $color_grey_a2;
      }
    }
  }

  &:active .image {
    transform: scale(0.95);
    transition-duration: 0.3s;
  }

  .title {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.23;
    letter-spacing: -0.32px;
    color: rgba(#fff, 0.95);
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .datetime {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    line-height: 0.9;
    letter-spacing: 0.11px;
    color: rgba(166, 166, 166, 0.97);
  }
}
</style>